import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "gatsby"
import LoadingSpinner from "../../components/loading-spinner"
import ArrowSubmit from "../../components/images/arrow-submit"
import TargetIcon from "../../components/images/target-icon"
import CalculatorFooter from "./footer"
import './style.css';
import SEO from "../../components/seo"

export default function CalculatorReport() {

  

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}

    useEffect(() => {
      addWebEvent("visit", "financial-calculator-report")
    },[]);



  const formatter = typeof window !== "undefined" ? new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',maximumFractionDigits: 0}) : "";
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  //formatter.format(max)

  function calculatePayment() {

    var uuid = require("uuid");
    var request_id = uuid.v4();

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);

    var formatted_address = urlParams.get("formatted_address"); // retrieve variable
    var property_value = urlParams.get("property_value"); // retrieve variable
    var mortgage_balance = urlParams.get("mortgage_balance"); // retrieve variable
    var income_level = urlParams.get("income_level"); // retrieve variable
    var credit_score = urlParams.get("credit_score"); // retrieve variable
    var show_footer = urlParams.get("show_footer"); // retrieve variable

    //alert (request_id + " " + formatted_address + " " + property_value + " " + mortgage_balance + " " + income_level + " HERE: " + credit_score);
    //GET ALL ORDERS IDS

    //IF SHOW FOOTER FALSE NOT SHOW FOOTER
    useEffect(() => {
      if (show_footer == "false") { document.getElementById("show_footer").className = "hidden"; }
    },[]);

    useEffect(() => {

    var data = new FormData();
    data.append("request_id", request_id);
    data.append("address", formatted_address);
    data.append("property_value", property_value);
    data.append("mortgage_balance", mortgage_balance);
    data.append("household_income", income_level);
    data.append("credit_score", credit_score);

    //alert("request_id" + request_id + "address" + formatted_address + "property_value" + property_value + "mortgage_balance" + mortgage_balance + "household_income" + income_level + "credit_score" + credit_score )

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/calculatePayment");
    xhr.send(data);
    xhr.onreadystatechange = function() {

        //useEffect(() => {
  
          if (xhr.readyState === XMLHttpRequest.DONE) {
    
            //READ DATA
            var response = xhr.responseText;
            
            //document.getElementById("demo").innerHTML = response;
            var response_obj = JSON.parse(response);
          
            const lender_list = [
            //"QUICKEN LOANS INC.",
            //"WELLS FARGO BANK", 
            //"NATIONAL ASSOCIATION",
            //"UNITED SHORE FINANCIAL SERVICES, LLC",
            //"BANK OF AMERICA",
            //"NATIONAL ASSOCIATION",
            //"JPMORGAN CHASE BANK",
            //"NATIONAL ASSOCIATION",
            //"LOANDEPOT.COM, LLC",
            //"CALIBER HOME LOANS, INC.",
            //"FAIRWAY INDEPENDENT MORTGAGE CORPORATION",
            //"US BANK NATIONAL ASSOCIATION",
            //"GUARANTEED RATE, INC"];
            "Verifying site address",
            "Checking Cal HFA Grant Eligibility",
            "Finding The Best Loan Product",
            "Calculating monthly payments",
            "Searching for rental comparables",
            "Calculating monthly income"];
          
            //document.getElementById("progress-spinner").hidden = false;

            lender_list.forEach(function(item, i) {

                setTimeout(
                  function(){

                    try {
                        document.getElementById("progress-line").innerHTML = item;
                            
                        //<small className="font-light tracking-wide text-gray-400 pt-16" text-center>' + item + '</small>'; 
                    
                        } catch (e) { //location.reload(); 
                        }
                    //document.getElementById("full-page-content").innerHTML = i; 

                    if (i == 5) {

                          try {

                            //SHOW REPORT DATA

                            //INPUT
                            document.getElementById("input-data-satelite-image").innerHTML = '<img src="' + response_obj.satelite_image + '" width="100%"></img>';
                            document.getElementById("input-data-formatted_address").innerHTML = formatted_address;
                            document.getElementById("input-data-property_value_option_name").innerHTML = typeof window !== "undefined" ? formatter.format(property_value) : ""
                            document.getElementById("input-data-mortgage_balance_option_name").innerHTML = typeof window !== "undefined" ? formatter.format(mortgage_balance) : ""
                            document.getElementById("input-data-household_income_option_name").innerHTML = typeof window !== "undefined" ? formatter.format(income_level) : ""
                            document.getElementById("input-data-credit_score_option_name").innerHTML = credit_score;

                            //MODAL-00
                            document.getElementById("product00_estimated_project_cost").innerHTML = response_obj.product00_estimated_project_cost;
                            document.getElementById("product00_apr").innerHTML = response_obj.product00_apr;
                            document.getElementById("product00_term").innerHTML = response_obj.product00_term;
                            document.getElementById("product00_estimated_monthly_payments").innerHTML = response_obj.product00_estimated_monthly_payments;
                            document.getElementById("product00_earning_potential").innerHTML = response_obj.product00_earning_potential;
                            document.getElementById("product00_cal_fha_discount").innerHTML = response_obj.cal_fha_discount;

                            //MODAL-01
                            document.getElementById("product01_estimated_project_cost").innerHTML = response_obj.product01_estimated_project_cost;
                            document.getElementById("product01_apr").innerHTML = response_obj.product01_apr;
                            document.getElementById("product01_term").innerHTML = response_obj.product01_term;
                            document.getElementById("product01_estimated_monthly_payments").innerHTML = response_obj.product01_estimated_monthly_payments;
                            document.getElementById("product01_earning_potential").innerHTML = response_obj.product01_earning_potential;
                            document.getElementById("product01_cal_fha_discount").innerHTML = response_obj.cal_fha_discount;

                            //MODAL-02
                            document.getElementById("product02_estimated_project_cost").innerHTML = response_obj.product02_estimated_project_cost;
                            document.getElementById("product02_apr").innerHTML = response_obj.product02_apr;
                            document.getElementById("product02_term").innerHTML = response_obj.product02_term;
                            document.getElementById("product02_estimated_monthly_payments").innerHTML = response_obj.product02_estimated_monthly_payments;
                            document.getElementById("product02_earning_potential").innerHTML = response_obj.product02_earning_potential;
                            document.getElementById("product02_cal_fha_discount").innerHTML = response_obj.cal_fha_discount;

                            //SHOW FULL REPORT
                            document.getElementById("header").className="bg-green-900 flex relative justify-center text-white text-center h-screen pdf px-6 flex-col";
                            document.getElementById("input-data").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                            document.getElementById("input-data").hidden=false;

                            //CHECK IF ADDRESS IN USA
                            if (response_obj.is_usa == "Y") {

                                  document.getElementById("calc-section").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                  document.getElementById("calc-section").hidden=false; 
                                  document.getElementById("calculator-receive-estimate-limited-section").hidden=false;  

                                  //IF CALIFORNIA ALWAYS SHOW HFA SECTION
                                  if (response_obj.is_california == "Y") {
                                  document.getElementById("hfa-california-grant-section").hidden=false; }
                                  else { document.getElementById("hfa-california-grant-section").hidden=true; }

                                  //IF ELIGIBLE
                                  if (response_obj.eligible00 == "Y" && response_obj.eligible01 == "Y" && response_obj.eligible02 == "Y") {

                                            //document.getElementById("eligible-ltv").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                            //document.getElementById("eligible-ltv").hidden=false; 
                                            //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=false; 
                                            
                                            //document.getElementById("eligible-balance").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                            //document.getElementById("eligible-balance").hidden=false; 

                                            //document.getElementById("eligible-credit").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                            //document.getElementById("eligible-credit").hidden=false; 
                                            
                                          }

                                  //IF NOT ELIGIBLE
                                  else {

                                            //IF LTV >= 80
                                            if (response_obj.loan_to_value00 >= 80) {
                                              document.getElementById("no-eligible-ltv").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                              document.getElementById("no-eligible-ltv").hidden=false; 
                                              //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=false; 

                                              document.getElementById("no-eligible-ltv-msg").innerHTML = "\
                                                        <h5>Hi, your Loan to Value (LTV=Loan Balance/Property Value) ratio is above \
                                                        80. More financing options are available when LTV is below 80. Below, we're \
                                                        presenting a estimated payments for a 79.9 LTV.</h5>";
                                            }

                                            //IF MB > PV
                                            //if (mortgage_balance > property_value) {
                                            //  document.getElementById("no-eligible-balance").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                            //  document.getElementById("no-eligible-balance").hidden=false; 
                                              //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=false; 

                                            //  document.getElementById("no-eligible-balance-msg").innerHTML = "\
                                            //            <h5>Your Mortgage Balance is higher than your Property Value, so your \
                                            //            Loan to Value (LTV=Loan Balance/Property Value) ratio is above 100. \
                                            //            Financing is most available when LTV is below 80. This table presents \
                                            //            payment estimates for an LTV of 79.9.</h5>";
                                            //}

                                            //IF CS < 620
                                            if (credit_score < 620) {
                                              document.getElementById("no-eligible-credit").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                                              document.getElementById("no-eligible-credit").hidden=false; 
                                              //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=false; 

                                              document.getElementById("no-eligible-credit-msg").innerHTML = "\
                                                        <h5>Hey there, it looks like your credit score is below 619. More financing options are available for \
                                                        Credit Scores higher than 620. Below, we're presenting estimated payments for a 620 Credit Score.</h5>";
                                            }

                                  }

                            }
                            else {

                              document.getElementById("no-usa").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                              document.getElementById("no-usa").hidden=false; 
                              //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=false; 

                            }
                            //SUCESS CHECKMARK
                            //<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="40px">
                            //<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            //<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                            //</svg>

                            //if (response_obj.eligible01 == "Y") { 
                            //          document.getElementById("product-01").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                            //          document.getElementById("product-01").hidden=false; }

                            //if (response_obj.eligible02 == "Y") { 
                            //          document.getElementById("product-02").className="relative flex flex-col md:flex-row flex-wrap justify-between bg-white md:px-20 px-6 font-light tracking-wide";
                            //          document.getElementById("product-02").hidden=false; }

                            document.getElementById("progress-line").innerHTML= '<h2 className="bg-green-900 flex relative justify-center text-white h-screen pdf px-6 flex-col">\
                                                                                  Meet <span className="font-bradford">Your Payment Plan</span></h2>';
                                                                                  
                            document.getElementById("scroll-line").hidden=false;   
                            document.getElementById("spinner").hidden=true;    
                            document.getElementById("progress-title").hidden=true;    

                            //document.getElementById("calculator-create-account-section").hidden=false;  
                            //document.getElementById("calculator-receive-estimate-section").hidden=false;  
                            
                            //document.getElementById("progress-line").innerHTML = report_html;

                            setTimeout(function(){
                              scrollToReport();
                              }, 1000);

                            return 
                          }catch (e) {}
                      }

                  }, i * 1000);
              });
          }
        //});
      }
    },[]);
    return request_id
  }

  var request_id = calculatePayment();

  //if (sessionStorage.getItem("formatted_address") !== "") { calculatePayment(formatted_address) }

  function scrollToReport(){
    const divElement = document.getElementById('start-report');
    divElement.scrollIntoView({ behavior: 'smooth' });
    //var entire_html = document.documentElement.innerHTML;
    //document.getElementById("demo").innerText = entire_html;
  }

  //document.getElementById('demo').innerText = ReactDOM.render(document.getElementById('root'));
  //document.getElementById("demo").innerText = "HERE";
  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);
  //function onDocumentLoadSuccess({ numPages }) {
  //  setNumPages(numPages);
  // }
  //ReactPDF.renderToStream(<Document />);

  //ARROW BACK
  function startOver() {
    //useEffect(() => {

      const queryString = typeof window !== "undefined" ? window.location.search : ""
      const urlParams = new URLSearchParams(queryString);
      //typeof window !== "undefined" ? window.location.replace("/financial-calculator/" + "?property_value=" + urlParams.get("property_value") + "&mortgage_balance=" + urlParams.get("mortgage_balance") + "&income_level=" + urlParams.get("income_level") + "&credit_score=" + urlParams.get("credit_score")) : ""
      typeof window !== "undefined" ? window.location.replace("/financial-calculator/") : ""
      
    //});
  }

  return (
    <>
      <SEO title="Calculator" />
      <div className="relative w-full text-center h-auto">
        {
          //relative w-full text-center overflow-x-scroll h-auto
        }
        <div className="absolute top-0 left-0 right-0 z-20 p-6 flex justify-between">
          <div>
            <button
              className="block focus:outline-none"
              //onClick={() => dispatch({ type: Type.PreviousStep })}
              //onclick="window.location.href='/financial-calculator'"
              //onClick={this.sayHello}
              onClick={() => {startOver()}}
              style={{ transform: "rotate(180deg)" }}
            >
              <ArrowSubmit
                fill="white"
              ></ArrowSubmit>
            </button>
          </div>
          <Link to="/" className={`cursor-pointer`}>
            <TargetIcon
              fill="white"
              rotate={true}
            ></TargetIcon>
          </Link>
        </div>
      </div>

      <section id="header" className="bg-green-900 flex relative justify-center text-white text-center h-screen pdf px-6 flex-col" >
          
          <h2 id="progress-title" className="tracking-wide font-light text-center items-center">Looking for the best rates...</h2>
          <br></br>
          <br></br>
          <m id="progress-line"></m>
          <br></br>
          <div id="spinner" className="relative w-full text-center overflow-hidden h-auto">
            <LoadingSpinner />
          </div>
          <div id="scroll-line" hidden>
            <div className="absolute flex flex-col text-white fill-current items-center bottom-0 pb-4 scroll-down-pdf">
              <svg
                className="w-6 h-6 lg:block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35.75 22.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      className="cls-1"
                      points="20.21 16.35 20.21 22.68 35.75 6.51 35.75 0 20.21 16.35"
                    />
                    <polygon
                      className="cls-1"
                      points="0 6.51 15.54 22.68 15.54 16.41 0 0 0 6.51"
                    />
                  </g>
                </g>
              </svg>
              <small className="hidden md:hidden lg:block">Scroll</small>
            </div>
          </div>
      </section>

      <div id="start-report"></div>
      
      <section id="input-data" className="" hidden>
        <div className="md:w-2/5 py-6 md:pr-10 text-left">
            <br></br>
            <br></br>
            <m id="input-data-satelite-image"></m>
        </div>
        <div className="flex flex-col md:w-3/5">
        <br></br>
        <br></br>
          <div className="py-6 md:py-4 justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Your Address</small>
              <h6><m id="input-data-formatted_address"></m></h6>
          </div>
          <div className="py-6 md:py-4 justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Your Current Property value</small>
              <h5><m id="input-data-property_value_option_name"></m></h5>
          </div>
          <div className="py-6 md:py-4 justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Your Current Mortgage Balance</small>
              <h5><m id="input-data-mortgage_balance_option_name"></m></h5>
          </div>
          <div className="py-6 md:py-4 justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Your Current Household Income</small>
              <h5><m id="input-data-household_income_option_name"></m></h5>
          </div>
          <div className="py-6 md:py-4 justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Your Credit Score</small>
              <h5><m id="input-data-credit_score_option_name"></m></h5>
          </div>
        </div>
        <br></br>
      </section>

      <section id="no-eligible-ltv" className="" hidden>
        <table>
          <tbody>
          <tr>
          <td>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="40px">
              <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
            </svg>
          </td>
          <td>
            <div className="justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-18 text-left flex-wrap">Loan to Value</small>
              <m id="no-eligible-ltv-msg"></m>
            </div>
          </td>
          </tr>
          </tbody>
        </table>
      </section>

      <section id="no-eligible-balance" className="" hidden>
        <table>
          <tbody>
          <tr>
          <td>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="40px">
              <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
            </svg>
          </td>
          <td>
            <div className="justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-18 text-left flex-wrap">Mortgage Balance</small>
              <m id="no-eligible-balance-msg"></m>
            </div>
          </td>
          </tr>
          </tbody>
        </table>
      </section>

      <section id="no-eligible-credit" className="" hidden>
        <table>
          <tbody>
          <tr>
          <td>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="40px">
              <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
            </svg>
          </td>
          <td>
            <div className="justify-between text-left">
              <small className="font-light tracking-wide text-gray-400 pt-18 text-left flex-wrap">Credit Score</small>
              <m id="no-eligible-credit-msg"></m>
            </div>
          </td>
          </tr>
          </tbody>
        </table>
      </section>

      <div id="start-report"></div>
      
      <section id="no-usa" hidden>
        <div className="justify-between text-left">
          <small className="font-light tracking-wide text-gray-400 pt-18 text-left flex-wrap">Location</small>
          <h5>We’re so sorry that we do not currently service residential 
              homeowners outside of the United States. However, we do supply 
              developers outside of the US.</h5>
        </div>
        <div className="flex justify-center pb-10">
          <a href = "https://www.checksolarcost.com/developers">
            <button
              //onClick={() => submitContactForm("mortgage_consultation")}
              //type="submit"
              className={`relative transition transition-delay-longer w-100 bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mt-8 shadow-xl outline-none focus:outline-none`}
            >
              Developers
            </button>
            </a>
          </div>
      </section>

    <section id="calc-section" className="" hidden>
        <br></br>
        <table>
          <tbody>
          <tr>
            <td><img src="https://api.checksolarcost.com/v1/getImageFile/_modal-floorplans-web-vertical-00.png" width="30%"></img></td>
            <td class="middle-table-border"><img src="https://api.checksolarcost.com/v1/getImageFile/_modal-floorplans-web-vertical-01.png" width="35%"></img></td>
            <td><img src="https://api.checksolarcost.com/v1/getImageFile/_modal-floorplans-web-vertical-02.png" width="40%"></img></td>
          </tr>
          <tr>
            <td><m className="font-light pb-10 font-maxeville text-2xl text-center">Modal 00</m></td>
            <td class="middle-table-border"><m className="font-light pb-10 font-maxeville text-2xl text-center">Modal 01</m></td>
            <td><m className="font-light pb-10 font-maxeville text-2xl text-center">Modal 02</m></td>
          </tr>
          <tr>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly cost</small><br></br><h4><m id="product00_estimated_monthly_payments"></m></h4></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly cost</small><br></br><h4><m id="product01_estimated_monthly_payments"></m></h4></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly cost</small><br></br><h4><m id="product02_estimated_monthly_payments"></m></h4></td>
          </tr>
          <tr>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">APR</small><br></br><h6><m id="product00_apr"></m></h6></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">APR</small><br></br><h6><m id="product01_apr"></m></h6></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">APR</small><br></br><h6><m id="product02_apr"></m></h6></td>
          </tr>
          <tr>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Term</small><br></br><h6><m id="product00_term"></m></h6></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Term</small><br></br><h6><m id="product01_term"></m></h6></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Term</small><br></br><h6><m id="product02_term"></m></h6></td>
          </tr>
          <tr>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated project cost</small><br></br><h6><m id="product00_estimated_project_cost"></m></h6></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated project cost</small><br></br><h6><m id="product01_estimated_project_cost"></m></h6></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated project cost</small><br></br><h6><m id="product02_estimated_project_cost"></m></h6></td>
          </tr>

          <tr id="hfa-california-grant-section" hidden>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">California HFA Grant</small><br></br><h6 style={{color: "green"}}><m id="product00_cal_fha_discount"></m> *</h6></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">California HFA Grant</small><br></br><h6 style={{color: "green"}}><m id="product01_cal_fha_discount"></m> *</h6></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">California HFA Grant</small><br></br><h6 style={{color: "green"}}><m id="product02_cal_fha_discount"></m> *</h6></td>
          </tr>

          <tr>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly rental income</small><br></br><h4 style={{color: "green"}}><m id="product00_earning_potential"></m></h4></td>
            <td class="middle-table-border"><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly rental income</small><br></br><h4 style={{color: "green"}}><m id="product01_earning_potential"></m></h4></td>
            <td><br></br><small className="font-light tracking-wide text-gray-400 pt-16 text-center flex-wrap">Estimated monthly rental income</small><br></br><h4 style={{color: "green"}}><m id="product02_earning_potential"></m></h4></td>
          </tr>

          </tbody>
        </table>
    </section>
    
    <section id="show_footer" className="">
      <CalculatorFooter request_id={request_id}/>
    </section>
    </>
  )

}